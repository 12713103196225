import React, { useState, useContext } from 'react';
import DefaultContext from '../context/DefaultContext'
import StateListItem from './StateListItem';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'

const StateCard = ({ name, abbr, sites, _id }) => {

  const ctx = useContext(DefaultContext)

  const [newSite, setNewSite] = useState('')

  const addSite = () => {
    ctx.dispatch({
      type: 'ADD_SITE',
      abbr,
      site: newSite
    })
    ctx.db.states.updateOne({_id}, {'$push': {sites: newSite}})
    setNewSite('')
  }

  const removeSite = ({site, index}) => {
    ctx.dispatch({
      type: 'REMOVE_SITE',
      abbr,
      index
    })
    ctx.db.states.updateOne({_id}, {'$pull': {sites: site}})
  }

  const editSite = ({site, value}) => {
    ctx.dispatch({
      type: 'EDIT_SITE',
      abbr,
      site,
      value
    })
    ctx.db.states.updateOne({_id, sites: site}, {'$set': {'sites.$': value}})
  }

  return (
    <Card className="mb-3" style={{ border: '1px solid #ddd', borderRadius: 4}}>
    <Card.Body>
      <Card.Title>
        {name}
      </Card.Title>
      <Card.Subtitle className="mb-2 text-muted">
        {abbr}
      </Card.Subtitle>
    </Card.Body>
    <ListGroup variant="flush">
      <ListGroup.Item>
        Sites:
      </ListGroup.Item>
      {
          sites.map((s, i) => <StateListItem key={i} index={i} site={s} stitch={ctx.db} removeSite={() => removeSite({site: s,index: i })} editSite={editSite} />)
      }
      {sites.length < 5 && (
        <ListGroup.Item >
          <InputGroup size="sm">
              <FormControl value={newSite} onChange={(e) => setNewSite(e.target.value)} placeholder='"https://www.cpasitesolutions.com/"'/>
            <InputGroup.Append>
                <Button variant="success" onClick={() => addSite(newSite)}>
                <FontAwesomeIcon icon={faPlus} /> Add Site
              </Button>
            </InputGroup.Append>
          </InputGroup>
        </ListGroup.Item>
      )}

    </ListGroup>
  </Card>
)}

export default StateCard;