import React, { useState, useEffect } from 'react';
import ListGroup from 'react-bootstrap/ListGroup';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSave, faTimes } from '@fortawesome/free-solid-svg-icons'


const StateListItemEdit = ({ site, onSave, stitch, editSite}) => {

  const [siteText, setSiteText] = useState('')

  useEffect(() => {
    setSiteText(site)
  },[])
  
  const handleSave = () => {
    editSite(siteText)
    onSave(false)
  }

  return (
    <ListGroup.Item>
      <InputGroup size="sm">
        <FormControl value={siteText} onChange={(e) => setSiteText(e.target.value)}/>
        <InputGroup.Append>
          <Button variant="success" onClick={(e) => handleSave(e)}>
            <FontAwesomeIcon icon={faSave} />
          </Button>
          <Button onClick={() => onSave(false)}>
            <FontAwesomeIcon icon={faTimes} />
          </Button>
        </InputGroup.Append>
      </InputGroup>
    </ListGroup.Item>
  )
}

export default StateListItemEdit;