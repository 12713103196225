import React, {useState, useEffect, useContext} from 'react';
import DefaultContext from './context/DefaultContext'
import StateCard from './components/StateCard';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import AuthControls from './components/AuthControls';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'

const AdminPanel = () => {

  const ctx = useContext(DefaultContext)

  const [search, setSearch] = useState('')
  const [regex, setRegex] = useState(new RegExp('', 'i'))

  useEffect(() => {
    setRegex(new RegExp(search, 'i'))
  }, [search])


  return (
  <div style={{paddingLeft: '15px', paddingRight: '15px', marginLeft: 'auto', marginRight: 'auto'}}>
    <AuthControls {...ctx.db} />
      {(ctx.db.client.auth.isLoggedIn && ctx.db.client.auth.authInfo.loggedInProviderType === 'local-userpass') && (
      <React.Fragment>
        <InputGroup className="mb-3">
        <InputGroup.Prepend>
          <InputGroup.Text>
                <FontAwesomeIcon icon={faSearch} />
          </InputGroup.Text>
          </InputGroup.Prepend>
          <FormControl onChange={(e) => setSearch(e.target.value)} value={search} placeholder="Search for a State (abbr or name)" />
        </InputGroup>
        <Row>
          {
              ctx.states.filter(s => (
                regex.test(ctx.entities.states[s].abbr) || regex.test(ctx.entities.states[s].name)
              )).map(s => {
                const stateNode = ctx.entities.states[s];
                return <Col md={4} key={stateNode.abbr}><StateCard {...stateNode}/></Col>
              })
          }
        </Row>
      </React.Fragment>
    )}
    
  </div>
)}

export default AdminPanel;