const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_SHOW':
      return {
        ...state,
        entities: {
          ...state.entities,
          show: action.value
        }
      };
    case 'SET_STATE_DATA':
      return {
        ...state,
        entities: {
          ...state.entities,
          stateData: action.value
        }
      };
    case 'SET_STATES_DATA':
      return {
        ...state,
        entities: {
          ...state.entities,
        }
      };
    case 'NORMALIZE_STATES':
      return {
        ...state,
        states: action.states.map(s => s.abbr),
        entities: {
          ...state.entities,
          states: action.states.reduce((acc, cv) => ({
            ...acc,
            [cv.abbr]: cv
          }), {})
        }
      };
    case 'ADD_SITE':
      return {
        ...state,
        entities: {
          ...state.entities,
          states: {
            ...state.entities.states,
            [action.abbr]: {
              ...state.entities.states[action.abbr],
              sites: [
                ...state.entities.states[action.abbr].sites,action.site
              ]
            }
          }
        }
      }
    case 'REMOVE_SITE':
      return {
        ...state,
        entities: {
          ...state.entities,
          states: {
            ...state.entities.states,
            [action.abbr]: {
              ...state.entities.states[action.abbr],
              sites: state.entities.states[action.abbr].sites.filter((s,i) => i !== action.index)
            }
          }
        }
      }
      case 'EDIT_SITE':
      return {
        ...state,
        entities: {
          ...state.entities,
          states: {
            ...state.entities.states,
            [action.abbr]: {
              ...state.entities.states[action.abbr],
              sites: state.entities.states[action.abbr].sites.map(site => site === action.site ? action.value : site
              )
            }
          }
        }
      }
    default:
      return state
  }
}

export default reducer;