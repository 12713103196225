import React, { useContext } from 'react';
import USAMap from 'react-usa-map';

import DefaultContext from '../context/DefaultContext'



const MapContainer = () => {

  const ctx = useContext(DefaultContext)

  const statesCustomConfig = () => ctx.states.reduce((acc, cv) => ({
    ...acc,
    [cv]: {
      clickHandler: (e) => {
        ctx.dispatch({ type: 'SET_STATE_DATA', value: ctx.entities.states[cv] });
        ctx.dispatch({ type: 'SET_SHOW', value: true });
      }
    }
  }), {})

  const mapHandler = (e) => {
    console.log('e', e.target.dataset.name)
  }

  const style = process.env.NODE_ENV === 'development' ? { display: 'flex', width: '100%' } : {};

  return (
  <div style={style}><USAMap onClick={mapHandler} customize={statesCustomConfig()} /></div>
)}

export default MapContainer;