import React, { useEffect, useReducer } from 'react';
import {
  Stitch,
  RemoteMongoClient,
  AnonymousCredential
} from "mongodb-stitch-browser-sdk";

import DefaultContext from './context/DefaultContext'
import reducer from './reducer'

import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Modal from 'react-bootstrap/Modal';
import AdminPanel from './AdminPanel';
import MapContainer from './components/MapContainer'

let appId = "top-sites-usa-prosites-zrbzc";

const client = Stitch.initializeDefaultAppClient(appId);

const db = client.getServiceClient(RemoteMongoClient.factory, "mongodb-atlas").db('prositescorp');
let states = db.collection("states");
let sites = db.collection("site");

function App({ layout }) {

  const initialState = {
    db: {
      client,
      states,
      sites
    },
    entities: {
      states: [],
      show: false,
      stateData: {}
    },
    states: []
  }

  const [state, dispatch] = useReducer(reducer, initialState);

  const handleClose = () => dispatch({type: 'SET_SHOW', value: false});

  useEffect(() => {
    if(!state.db.client.auth.isLoggedIn) {
      state.db.client.auth.loginWithCredential(new AnonymousCredential())
      .then(_ => {
        state.db.states.find({}, { limit: 51 }).toArray().then(docs => {
          dispatch({ type: 'NORMALIZE_STATES', states: docs })
        })
      })
    } else {
      state.db.states.find({}, { limit: 51 }).toArray().then(docs => {
        dispatch({ type: 'NORMALIZE_STATES', states: docs })
      })
    }
  }, [])

  return (
    <DefaultContext.Provider value={{ ...state, dispatch }}>
      {layout === 'admin' ? <AdminPanel /> : <MapContainer />}
      <Modal show={state.entities.show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Top websites in {state.entities.stateData.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ul>
            {
              state.entities.stateData.sites && state.entities.stateData.sites.map((s) => <li><a href={s} target="_blank" rel="noopener noreferrer">{s}</a></li>)
            }
          </ul>
        </Modal.Body>
      </Modal>
    </DefaultContext.Provider>
  );
}

export default App;
