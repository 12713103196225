import React from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

import {
  // UserPasswordAuthProviderClient,
  UserPasswordCredential,
  AnonymousCredential
} from "mongodb-stitch-browser-sdk";

class AuthControls extends React.Component {
   constructor(props){
      super(props)
      this.state = {
        userData: props.client.auth.isLoggedIn ? props.client.auth.currentUser.profile.data : null,
        credentials: {
          email: '',
          password: ''
        },
        login: {
          email: 'support@cpasitesolutions.com',
          password: ''
        },
        reset: ''
      }
      this.client = props.client;
   }

  render() {

    let authed = this.client.auth.isLoggedIn && this.client.auth.authInfo.loggedInProviderType === 'local-userpass';

    if(!authed) {
      this.client.auth.loginWithCredential(new AnonymousCredential())
    }

    // let emailPassClient = this.client.auth.getProviderClient(UserPasswordAuthProviderClient.factory)

    if(this.client.auth.hasRedirectResult()){
        this.client.auth.handleRedirectResult().then(user=>{
          this.setState({userData:user.profile.data})
        });
    }
    let logout = () => this.client.auth.logout().then(() => window.location.reload());

    return (
        <div className="my-3">
        {authed
          ? <div style={{display: 'flex', justifyContent: 'flex-end'}}>
              <Button onClick={() => logout()} size="md">Logout</Button>
            </div>
          : null}
        {!authed
          ? <div className="login-links-panel">
            {/* <div>
                <h2>Create User Account</h2>
                <input type="email" value={this.state.credentials.email} onChange={(e) => {
                  this.setState({
                    credentials: {
                      ...this.state.credentials,
                      email: e.target.value
                    }
                  })
                }}/>
              <input type="password" value={this.state.credentials.password} onChange={(e) => {
                this.setState({
                  credentials: {
                    ...this.state.credentials,
                    password: e.target.value
                  }
                })
              }} />
              <button
                onClick={() => {
                  emailPassClient.registerWithEmail(this.state.credentials.email, this.state.credentials.password)
                    .then(() => {
                      console.log("Successfully sent account confirmation email!");
                    })
                    .catch(err => {
                      console.log("Error registering new user:", err);
                    });
                }}
              >submit</button>
              </div> */}
              
              {/* <div>
                <h2>Send Reset link</h2>
                <input type="email" value={this.state.reset} onChange={(e) => {
                  this.setState({
                    reset: e.target.value
                  })
                }}/>
                <button
                  onClick={() => {
                    emailPassClient.sendResetPasswordEmail(this.state.reset).then(() => {
                      console.log("Successfully sent password reset email!");
                    }).catch(err => {
                      console.log("Error sending password reset email:", err);
                    });
                  }}
                >send</button>
              </div> */}
              <div style={{maxWidth:'100%', width: 720, marginLeft: 'auto', marginRight: 'auto'}}>
                <Form onSubmit={(e) => {
                  e.preventDefault()
                  this.client.auth.loginWithCredential(new UserPasswordCredential(this.state.login.email, this.state.login.password))
                    .then(authedUser => {
                      console.log(`successfully logged in with id: ${authedUser.id}`)
                      document.location.reload();
                    })
                    .catch(err => console.error(`login failed with error: ${err}`))
                }}>
                  {/* <Form.Group>
                    <Form.Label>Email address</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="Enter email"
                      value={this.state.login.email}
                      onChange={(e) => {
                        this.setState({
                          login: {
                            ...this.state.login,
                            email: e.target.value
                          }
                        })
                      }} 
                    />
                  </Form.Group> */}
                  <Form.Group>
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                      type="password"
                      placeholder="Enter your password"
                      value={this.state.login.password}
                      onChange={(e) => {
                        this.setState({
                          login: {
                            ...this.state.login,
                            password: e.target.value
                          }
                        })
                      }}
                    />
                  </Form.Group>
                  <Button type="submit">
                    Login
                  </Button>
                </Form>
                {/* <input type="email" value={this.state.login.email} onChange={(e) => {
                  this.setState({
                    login: {
                      ...this.state.login,
                      email: e.target.value
                    }
                  })
                }} />
                <input type="password" value={this.state.login.password} onChange={(e) => {
                  this.setState({
                    login: {
                      ...this.state.login,
                      password: e.target.value
                    }
                  })
                }} />
              <button
                onClick={() => {
                  this.client.auth.loginWithCredential(new UserPasswordCredential(this.state.login.email, this.state.login.password))
                    .then(authedUser => {
                      console.log(`successfully logged in with id: ${authedUser.id}`)
                      document.location.reload();
                  })
                    .catch(err => console.error(`login failed with error: ${err}`))
                }}
              >login</button> */}
              </div>
            </div>
          : null}
        </div>
    );
  }
};

export default AuthControls;