import React, {useState} from 'react';
import StateListItemEdit from './StateListItemEdit';
import ListGroup from 'react-bootstrap/ListGroup';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faEdit } from '@fortawesome/free-solid-svg-icons'

const StateListItem = ({ site, index, stitch, removeSite, editSite }) => {
  const [edit, setEdit] = useState(false)
  return (
    !edit ? (
      <ListGroup.Item >
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ overflow: 'hidden', whiteSpace: 'nowrap' }}>
            {site}
          </div>
          <ButtonGroup>
            <Button
              onClick={() => setEdit(true)}
              size="sm"
            >
              <FontAwesomeIcon icon={faEdit} />
          </Button>
            <Button
              variant="danger"
              size="sm"
              onClick={removeSite}
            >
              <FontAwesomeIcon icon={faTrash} />
          </Button>
          </ButtonGroup>
        </div>
      </ListGroup.Item >
    ) : (
        <StateListItemEdit site={site} onSave={() => setEdit()} editSite={(value) => editSite({site, value})} stitch={stitch}/>
    )    
  )
}

export default StateListItem;